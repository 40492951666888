import React from "react";
import Layout from "../components/layout";
import aboutphoto from "../assets/images/about_us.png";

function About() {
    return(
        <Layout>
            <div className="bg-white">
            <div className="mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 items-center pt-8 gap-x-8 px-4 lg:max-w-7xl xl:max-w-7xl 2xl:max-w-7xl lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 lg:px-8  xl:px-8 2xl:px-8">
                <div className="pb-20">
                    <p className="text-md">Established in August 2012</p>
                    <h1 className="font-bold text-4xl pb-4 text-gray-700">Myanmar Synergy Garment</h1>

                    <dl className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 lg:gap-x-8">
                        <h2 className="font-bold text-xl text-gray-700">Our Mission</h2>
                        <p className="text-gray-500">To become a leading and responsible textile industry, which understand the human requirements and acts to balance these requirements by producing world-class products, keeping in view the social, economical and environmental concerns of the nation.</p>
                        <hr></hr>

                        <h2 className="font-bold text-xl text-gray-700">Our Vision</h2>
                        <p className="text-gray-500">To Manufacture products comparable to international standards, to be customer-focused and globally competitive through better quality, latest technology and continuous innovation.</p>
                        <hr></hr>

                        <h2 className="font-bold text-xl text-gray-700">Our Values</h2>
                        <p className="text-gray-500"><strong>QUALITY : </strong>We deliver the agreed quality on timely manner.</p>
                        <p className="text-gray-500"><strong>RELATIONS : </strong>xOur Success is based on good relations with all our partners and colleagues. We keep focus on creating value for our Customers and for the Group as a whole. </p>
                        <p className="text-gray-500"><strong>SOLUTION : </strong>Continuous innovation to provide solutions for our customers .</p>
                        <hr></hr>
                    </dl>
                </div>
                <div className="mb-8">
                    <img src={aboutphoto} alt="Our Values" className="rounded-lg" />
                </div>
            </div>
        </div>
        </Layout>
    );
}

export default About;