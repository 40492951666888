import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout";
import homebanner1 from "../assets/images/HomeBanner1.png";
import homebanner2 from "../assets/images/HomeBanner2.png";
import aboutphoto from "../assets/images/about.gif";
import warehousephoto from "../assets/images/warehouse_photo.jpg";
import patternphoto from "../assets/images/pattern_photo.jpg";
import cuttingphoto from "../assets/images/cutting_photo.jpg";
import sewingphoto from "../assets/images/sewing_photo.jpg";
import qcphoto from "../assets/images/qc_photo.jpg";
import finishingphoto from "../assets/images/finishing_photo.jpg";
import tescophoto from "../assets/images/tesco.png";
import icsphoto from "../assets/images/ics.png";
import sedexphoto from "../assets/images/sedex.png";
import bsciphoto from "../assets/images/bsci.png";

function Home() {
    return(
        <Layout>
            <div  data-theme="lofi">
           {/*             banner image           */}
           <div class="carousel w-full pt-2 pb-10">
                <div id="slide1" class="carousel-item relative w-full">
                    <img src={homebanner1} class="w-full" />
                    <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide2" class="btn btn-circle border-0">❮</a>
                        <a href="#slide2" class="btn btn-circle border-0">❯</a>
                    </div>
                </div>
                <div id="slide2" class="carousel-item relative w-full">
                    <img src={homebanner2} class="w-full" />
                    <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide1" class="btn btn-circle border-0">❮</a>
                        <a href="#slide1" class="btn btn-circle border-0">❯</a>
                    </div>
                </div>
            </div>
            {/*           end of banner image           */} 

            {/*           about image           */}
            <div className="container w-4/5 pt-16">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-8">
                    <div className="">
                        <img src={aboutphoto} alt="Synergy Garment" className="rounded-lg transition-transform duration-700 ..."></img>
                    </div>
                    <div className="pt-4">
                        <p className="text-md">Established in August 2012</p>
                        <h1 className="font-bold text-4xl pb-8 text-gray-700">Myanmar Synergy Garment</h1>

                        <p className="pb-4 text-gray-500">JV Cooperation between Korea and Myanmar 50% - 50% operated until 2020 before the COVID 19 Crises hit the garment industry.</p>
                        <p className="pb-8 text-gray-500">However, we started operating again from November 2021 with the investment of Myanmar Co-founder and become Citizen fully own private company.</p>

                        <Link to={'/about'}>
                            <button className="btn glass bg-black hover:bg-cyan-700 btn-sm sm:btn-sm md:btn-md lg:btn-md xl:btn-md 2xl:btn-md">
                                <p className="text-sm hover:tracking-wider px-4">View more</p>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            {/*           end of about image           */}

            {/*           factory process           */}
            <div className="py-20">
                <h1 className="font-bold text-3xl pb-8 text-center">Factory Process</h1>
                <div class="flex px-4 items-center justify-center bg-neutral-800">
                    <div class="grid grid-cols-1 gap-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-6">
                        <div class="group relative rounded-md cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                            <div class="h-96 w-72">
                                <img class="h-full w-full object-cover transition-transform duration-500 group-hover:scale-125" src={warehousephoto} alt="" />
                            </div>
                            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                            <div class="absolute inset-0 flex translate-y-[20%] flex-col items-center justify-center px-9 text-center transition-all duration-1000 group-hover:translate-y-0">
                                <h1 class="font-dmserif text-3xl font-bold text-white pb-10">Warehouse</h1>
                                <p className="text-white">- Storing</p>
                                <p className="text-white">- Checking fabric and accessory</p>
                            </div>
                        </div>

                        <div class="group relative rounded-md cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                            <div class="h-96 w-72">
                                <img class="h-full w-full object-cover transition-transform duration-500 group-hover:scale-125" src={patternphoto} alt="" />
                            </div>
                            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                            <div class="absolute inset-0 flex translate-y-[20%] flex-col items-center justify-center px-9 text-center transition-all duration-1000 group-hover:translate-y-0">
                                <h1 class="font-dmserif text-3xl font-bold text-white pb-10">Pattern and Marker</h1>
                                <p className="text-white">- Pattern and Marker developing and printing</p>
                            </div>
                        </div>

                        <div class="group relative rounded-md cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                            <div class="h-96 w-72">
                                <img class="h-full w-full object-cover transition-transform duration-500 group-hover:scale-125" src={cuttingphoto} alt="" />
                            </div>
                            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                            <div class="absolute inset-0 flex translate-y-[20%] flex-col items-center justify-center px-9 text-center transition-all duration-1000 group-hover:translate-y-0">
                                <h1 class="font-dmserif text-3xl font-bold text-white pb-10">Cutting</h1>
                                <p className="text-white">- Cutting</p>
                                <p className="text-white">- Numbering</p>
                                <p className="text-white">- Importing to sewing line</p>
                            </div>
                        </div>

                        <div class="group relative rounded-md cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                            <div class="h-96 w-72">
                                <img class="h-full w-full object-cover transition-transform duration-500 group-hover:scale-125" src={sewingphoto} alt="" />
                            </div>
                            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                            <div class="absolute inset-0 flex translate-y-[20%] flex-col items-center justify-center px-9 text-center transition-all duration-1000 group-hover:translate-y-0">
                                <h1 class="font-dmserif text-3xl font-bold text-white pb-10">Sewing Line</h1>
                                <p className="text-white">- Sewing by checking sample, color card and numbering</p>
                            </div>
                        </div>

                        <div class="group relative rounded-md cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                            <div class="h-96 w-72">
                                <img class="h-full w-full object-cover transition-transform duration-300 group-hover:scale-125" src={qcphoto} alt="" />
                            </div>
                            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                            <div class="absolute inset-0 flex translate-y-[20%] flex-col items-center justify-center px-9 text-center transition-all duration-1000 group-hover:translate-y-0">
                                <h1 class="font-dmserif text-3xl font-bold text-white pb-10">QC</h1>
                                <p className="text-white">- Checking the sewing output with byer confirmed sample</p>
                                <p className="text-white">- Rejecting and inmporting to finishing</p>
                            </div>
                        </div>

                        <div class="group relative rounded-md cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                            <div class="h-96 w-72">
                                <img class="h-full w-full object-cover transition-transform duration-500 group-hover:scale-125" src={finishingphoto} alt="" />
                            </div>
                            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                            <div class="absolute inset-0 flex translate-y-[20%] flex-col items-center justify-center px-9 text-center transition-all duration-1000 group-hover:translate-y-0">
                                <h1 class="font-dmserif text-3xl font-bold text-white pb-10">Finishing</h1>
                                <p className="text-white">- Making needle inspection</p>
                                <p className="text-white">- Dusk cleaning and heating</p>
                                <p className="text-white">- Hang tagging</p>
                                <p className="text-white">- Poly bag packing</p>
                                <p className="text-white">- Placing to the carton box</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*           end of factory process           */}

            {/*           client's logo           */}
            <div className="container w-4/5 py-8">
                <p className="text-sm text-center">Clients</p>
                <h1 className="font-bold text-3xl pb-8 text-center">Ethical Practice & Technical Audit</h1>

                <div className="grid grid-cols-4 gap-0">
                    <div className="p-2">
                        <img src={tescophoto} alt="Client Logo" width={106} height={86} className="rounded-md hover:opacity-75 ..." />
                    </div>
                    <div className="p-2">
                        <img src={icsphoto} alt="Client Logo" width={106} height={86} className="rounded-md hover:opacity-75 ..." />
                    </div>
                    <div className="p-2">
                        <img src={sedexphoto} alt="Client Logo" width={106} height={86} className="rounded-md hover:opacity-75 ..." />
                    </div>
                    <div className="p-2">
                        <img src={bsciphoto} alt="Client Logo" width={116} height={106} className="rounded-md hover:opacity-75 ..." />
                    </div>
                </div>
            </div>
            {/*           end of client's logo           */}
            {/*           get in touch           */}
            <div className="container w-4/5 py-20">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-8">
                    <div className="">
                        <embed src="https://www.youtube.com/embed/U0hkTDI8TMc" title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                            className="w-full h-64 md:h-80 lg:h-80 xl:h-96 2xl:h-96">
                        </embed>
                    </div>
                    <div className="pl-4">
                        <h1 className="font-bold text-4xl pb-4 text-gray-700">Market & Customers</h1>

                        <h1 className="text-cyan-900 font-semibold text-xl pt-4">Europe :</h1>
                        <ul className="list-none">
                            <li><p className="text-gray-500">UK (AUCHEN, GEMO)</p></li>
                            <li><p className="text-gray-500">BELGIUM (AUCHEN, GEMO)</p></li>
                            <li><p className="text-gray-500">FRANCE (AUCHEN, GEMO)</p></li>
                        </ul>

                        <h1 className="text-cyan-900 font-semibold text-xl pt-4">Asia :</h1>
                        <ul className="list-none">
                            <li><p className="text-gray-500">KOREA (E-Mart, TBJ, Tesco)</p></li>
                            <li><p className="text-gray-500">Morocco (AUCHEN)</p></li>
                        </ul>
                        <Link to={'/contact'}>
                            <button className="btn glass bg-black hover:bg-cyan-700 btn-sm sm:btn-sm md:btn-md lg:btn-md xl:btn-md 2xl:btn-md mt-4">
                                <p className="text-sm hover:tracking-wider px-4">Contact us</p>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            {/*           end of get in touch           */}
            </div>
        </Layout>
    );
}

export default Home;