import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.svg";

function Footer() {
  return (
    <footer className="p-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3" data-theme="lofi">
      <div>
        <img src={logo} alt="logo" width={66} height={56} />
        <h1 className="text-xl font-semibold">
          Myanmar Synergy
          <br />
          Garment
        </h1>
      </div>
      <div className="py-4">
        <ul>
          <h5 className="font-semibold">Factory Process</h5>
          <li className="text-sm">Warehouse</li>
          <li className="text-sm">Pattern Room</li>
          <li className="text-sm">Cutting</li>
          <li className="text-sm">Sewing</li>
          <li className="text-sm">Finishing</li>
        </ul>
      </div>
      <div className="py-4">
        <h5 className="font-semibold">Company</h5>
        <Link to={"/about"}><p className="text-sm">About us</p></Link>
        <Link to={"/contact"}><p className="text-sm">Contact us</p></Link>
        <p className="pt-4 text-sm">
          Copyright © {new Date().getFullYear()} - All right reserved by Myanmar
          Synergy Garment.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
