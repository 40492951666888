import React from "react";
import Layout from "../components/layout";
import locationphoto from "../assets/images/location.png";
import emailphoto from "../assets/images/email.png";
import phonephoto from "../assets/images/phone.png";

function Contact() {
    return(
        <Layout>
            <div className="bg-white py-12">
            <div className="mx-auto px-4 md:px-8 lg:px-8 xl:px-8 2xl:px-8">
                <div className="md:text-center lg:text-center xl:text-center 2xl:text-center">
                    <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900">
                    Myanmar Synergy Garment
                    </p>
                    <p className="mt-4 max-w-2xl text-gray-500 md:mx-auto lg:mx-auto xl:mx-auto 2xl:mx-auto"></p>
                </div>
                <div className="mt-10 md:px-10 lg:px-10 xl:px-10 2xl:px-10">
                    <dl className="space-y-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:gap-x-8 md:gap-y-10 md:space-y-0">
                        
                        <div class="flex items-center space-x-4">
                            <img src={phonephoto} alt="Location Icon" width="60" height="60" class="flex-none rounded-lg" loading="lazy" />
                            <div class="min-w-0 flex-auto space-y-1 font-semibold">
                                <p class="text-gray-700 dark:text-cyan-900 font-semibold leading-6">
                                    Phone
                                </p>
                                <h2 class="text-gray-500 text-sm">
                                01-684704
                                </h2>
                            </div>
                        </div>

                        <div class="flex items-center space-x-4">
                            <img src={emailphoto} alt="Location Icon" width="60" height="60" class="flex-none rounded-lg" loading="lazy" />
                            <div class="min-w-0 flex-auto space-y-1 font-semibold">
                                <p class="text-gray-700 dark:text-cyan-900 font-semibold leading-6">
                                    Email
                                </p>
                                <h2 class="text-gray-500 text-sm">
                                myanmarsynergygarment@gmail.com
                                </h2>
                            </div>
                        </div>

                        <div class="flex items-center space-x-4">
                            <img src={locationphoto} alt="Location Icon" width="60" height="60" class="flex-none rounded-lg" loading="lazy" />
                            <div class="min-w-0 flex-auto space-y-1 font-semibold">
                                <p class="text-gray-700 dark:text-cyan-900 font-semibold leading-6">
                                    Location
                                </p>
                                <h2 class="text-gray-500 text-sm">
                                No.18/19, Depeyin Wun Htauk U Myae Street, Hlaing Thar Yar Industrial Zone (2), Yangon, Myanmar
                                </h2>
                            </div>
                        </div>

                    </dl>
                </div>
            </div>

            <div className="px-4 pt-16">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3818.4174300616223!2d96.07307490000001!3d16.8552329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c195637e514299%3A0x9a5ff863ed88baca!2sMyanmar%20Synergy%20Garment%20Co.%2CLd!5e0!3m2!1sen!2smm!4v1666854729137!5m2!1sen!2smm" title="YouTube video player" frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    className="w-full h-96">
                </iframe>
            </div>
        </div>
        </Layout>
    );
}

export default Contact;