import React, {useState} from "react";
import { Link } from "react-router-dom";
import logo from '../logo.svg';

function Header() {

    const [showMenu, setShowMenu] = useState(false)

    return(
        <nav className="w-full text-base-content" data-theme="lofi">
            <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
                <div className="flex items-center">
                    <Link to={'/'} className="normal-case"><img src={logo} alt="logo" width={66} height={56} className="hover:animate-bounce" /></Link>
                </div>
                <div className="block lg:hidden pr-4">
                    <button onClick={() => setShowMenu(!showMenu)} id="nav-toggle" className="flex items-center p-1  focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                        <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>
                <div className={`w-full flex-grow lg:flex lg:items-center lg:w-auto ${showMenu ? 'flex' : 'hidden'}  mt-2 lg:mt-0  p-4 lg:p-0 z-20`} id="nav-content">
                    <ul className="list-reset lg:flex justify-end flex-1 items-center">
                        <li className="p-2 text-lg hover:tracking-wider"><Link to={'/'}>Home</Link></li>
                        <li className="p-2 text-lg hover:tracking-wider"><Link to={'/about'}>About us</Link></li>
                        <li className="p-2 text-lg hover:tracking-wider"><Link to={'/contact'}>Contact us</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;