import './App.css';
import Layout from './components/layout';
import Home from './pages/home_page';
import About from './pages/about_page';
import Contact from './pages/contact_page';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className='App'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='/about' element={<About></About>}></Route>
            <Route path='/contact' element={<Contact></Contact>}></Route>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
